import React from 'react';
import { Layout } from "components/common";
import "./styles.sass";

const BlogLayout = ({ children, showLinksInFooter = false, showM6Banner = true }) => (
  <Layout showLinksInFooter={showLinksInFooter} showM6Banner={showM6Banner}>
    <div className="blog-root">{children}</div>
  </Layout>
);

export default BlogLayout;
